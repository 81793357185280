<template>
  <v-combobox
    outlined
    dense
    v-model="value[valueKey]"
    :error-messages="error_messages"
    :data-vv-name="vv_name"
    data-vv-validate-on="blur"
    v-validate="validate"
    :items="items"
    item-text="nm"
    item-value="nm"
    :clearable="clearable"
    single-line
    hide-details
    :filter="filter"
    :label="label"
    @input="$emit('input')"
    @blur="show = false"
  />
</template>
<script>
import axios from "axios";
export default {
  name: "CitySelect",
  props: [
    "value",
    "valueKey",
    "label",
    "error_messages",
    "vv_name",
    "validate",
    "clearable",
  ],
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.loadCountry();
  },
  methods: {
    filter(item, queryText, itemText) {
      queryText = queryText.toLowerCase();
      let searchFields = [item.nm];

      return searchFields.some((x) => x.toLowerCase().includes(queryText));
    },
    loadCountry() {
      this.url = `https://raw.githubusercontent.com/IagoLast/pselect/master/data/municipios.json`;
      axios
        .get(this.url)
        .then((response) => {
          this.items = response.data;
        })
        .catch((errorResponse) => {
          //console.log(errorResponse);
        });
    },
  },
};
</script>